import React from 'react';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

// Base font size for scaling
const baseFontSize = 10;

// Stylesheet with variables for consistency
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    padding: 15,
  },
  heading: {
    fontSize: baseFontSize + 2,
    fontWeight: 'bold',
    textDecoration: 'underline',
    marginBottom: 6,
    textAlign: 'center',
  },
  label: {
    fontSize: baseFontSize,
    marginBottom: 2,
  },
  field: {
    fontSize: baseFontSize + 2,
    fontWeight: 'bold',
    borderBottomWidth: 1,
    borderBottomColor: '#000000',
    marginBottom: 4,
    paddingVertical: 2,
    minHeight: 20,
  },
  table: {
    marginTop: 8,
  },
  tableRow: {
    flexDirection: 'row',
    marginBottom: 2,
  },
  tableCell: {
    fontSize: baseFontSize,
    flex: 1,
    borderWidth: 1,
    borderColor: '#000000',
    padding: 2,
    textAlign: 'center',
  },
  checkBoxGrid: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 4,
    justifyContent: 'space-between',
  },
  checkBoxItem: {
    width: '9%',
    alignItems: 'center',
    marginBottom: 4,
  },
  checkBoxLabel: {
    fontSize: baseFontSize - 2,
    marginBottom: 2,
  },
  checkBox: {
    width: 10,
    height: 10,
    borderWidth: 1,
    borderColor: '#000',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkedBox: {
    width: 6,
    height: 6,
    backgroundColor: '#000',
  },
  signature: {
    marginTop: 20,
    alignItems: 'flex-end',
  },
  childrenTable: {
    marginTop: 10,
    marginBottom: 10,
  },
  childRow: {
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: '#000000',
    marginBottom: -1, // Pour éviter les doubles bordures
  },
  childNameCell: {
    flex: 2,
    padding: 8,
    borderRightWidth: 1,
    borderRightColor: '#000000',
  },
  childDateCell: {
    flex: 1,
    padding: 8,
  },
});

const CheckBox = ({ checked }) => (
  <View style={styles.checkBox}>
    {checked && <View style={styles.checkedBox} />}
  </View>
);

function formatDate(dateString) {
  if (!dateString) return '';
  const date = new Date(dateString);
  if (isNaN(date)) return '';
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

const CheckBoxTable = ({ lastUpdatedYear }) => {
  const years = Array.from({ length: 20 }, (_, i) => 2024 + i);

  return (
    <View style={styles.checkBoxGrid}>
      {years.map((year) => (
        <View key={year} style={styles.checkBoxItem}>
          <Text style={styles.checkBoxLabel}>{year}</Text>
          <CheckBox checked={year <= lastUpdatedYear} />
        </View>
      ))}
    </View>
  );
};

const FamilyStatusTable = ({ maritalStatus }) => {
  const statuses = [
    { key: 'married', label: 'Marié' },
    { key: 'pacs', label: 'Pacsé' },
    { key: 'single', label: 'Célibataire' },
    { key: 'divorcedWithChildren', label: 'Divorcé avec enfants' },
    { key: 'divorcedWithoutChildren', label: 'Divorcé sans enfants' },
    { key: 'widowed', label: 'Veuve/Veuf' },
  ];

  return (
    <View>
      <Text style={[styles.label, { marginBottom: 5 }]}>SITUATION FAMILIALE</Text>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
        {statuses.map((status) => (
          <View key={status.key} style={{
            borderWidth: 1,
            borderColor: '#000000',
            padding: 5,
            width: '15%',
            alignItems: 'center'
          }}>
            <Text style={styles.label}>{status.label}</Text>
            <CheckBox checked={maritalStatus === status.key} />
          </View>
        ))}
      </View>
    </View>
  );
};

const AdherentPDF = ({ adherent, children }) => {
  const lastUpdatedYear = new Date(adherent.contributionUpdatedAt).getFullYear();

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Header */}
        <View>
          <Text style={styles.heading}>L'association El Mektoub</Text>
          <Text style={styles.heading}>Bulletin d'adhésion</Text>
        </View>

        {/* Personal Information */}
        <View style={{ marginTop: 10 }}>
          <Text style={styles.label}>NOM ET PRÉNOM</Text>
          <Text style={styles.field}>{adherent.mrName}</Text>

          <Text style={styles.label}>NOM ET PRÉNOM (CONJOINT)</Text>
          <Text style={styles.field}>{adherent.mrsName}</Text>

          <View style={styles.tableRow}>
            <View style={{ flex: 1, marginRight: 5 }}>
              <Text style={styles.label}>DATE DE NAISSANCE</Text>
              <Text style={styles.field}>{formatDate(adherent.birthDate)}</Text>
            </View>
            <View style={{ flex: 1 }}>
              <Text style={styles.label}>DATE DE NAISSANCE (CONJOINT)</Text>
              <Text style={styles.field}>{formatDate(adherent.birthDateLine2)}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={{ flex: 1, marginRight: 5 }}>
              <Text style={styles.label}>N° DE SÉCURITÉ SOCIALE</Text>
              <Text style={styles.field}>{adherent.socialSecurityNumber}</Text>
            </View>
            <View style={{ flex: 1 }}>
              <Text style={styles.label}>N° DE SÉCURITÉ SOCIALE (CONJOINT)</Text>
              <Text style={styles.field}>{adherent.socialSecurityNumberLine2}</Text>
            </View>
          </View>

          <Text style={styles.label}>ADRESSE</Text>
          <Text style={styles.field}>{adherent.address}</Text>

          <View style={styles.tableRow}>
            <View style={{ flex: 1, marginRight: 5 }}>
              <Text style={styles.label}>TÉLÉPHONE</Text>
              <Text style={styles.field}>{adherent.phone}</Text>
            </View>
            <View style={{ flex: 1 }}>
              <Text style={styles.label}>ADRESSE MAIL</Text>
              <Text style={styles.field}>{adherent.email}</Text>
            </View>
          </View>

          <Text style={styles.label}>NATIONALITÉ</Text>
          <Text style={styles.field}>{adherent.nationality}</Text>

          <Text style={styles.label}>LIEU D'INHUMATION</Text>
          <Text style={styles.field}>{adherent.burialSite}</Text>

          <Text style={styles.label}>STATUT DE COTISATION</Text>
          <Text style={styles.field}>{adherent.contributionStatus}</Text>
        </View>

        {/* Family Status */}
        <FamilyStatusTable maritalStatus={adherent.maritalStatus} />

        {/* Children Information */}
        <View style={styles.childrenTable}>
          <Text style={[styles.label, { marginBottom: 5 }]}>NOM DES ENFANTS ET DATE DE NAISSANCE</Text>
          {[...Array(5)].map((_, index) => (
            <View key={index} style={styles.childRow}>
              <View style={styles.childNameCell}>
                <Text>{children[index]?.name || ''}</Text>
              </View>
              <View style={styles.childDateCell}>
                <Text>{children[index]?.birthDate ? formatDate(children[index].birthDate) : ''}</Text>
              </View>
            </View>
          ))}
        </View>

        {/* Commitments */}
        <View style={{ marginTop: 10 }}>
          <Text style={styles.label}>Je m'engage à respecter le règlement.</Text>
          <Text style={styles.label}>La cotisation annuelle du 1 janvier au 28 février.</Text>
          <Text style={styles.label}>Un enfant ayant eu 25 ans dans l'année en cours cotise 25 euros l'année</Text>
          <Text style={styles.label}>Un enfant se mariant avant 25 ans cotise 55€ l'année</Text>
          <Text style={styles.label}>Pour un enfant qui a 26 ans dans l'année en cours doit cotisé le retard de 50 euros</Text>
          
          <View style={{ marginTop: 5, marginBottom: 5 }}>
            <Text style={styles.label}>Nom et téléphone du délégué : {adherent.delegateName}</Text>
          </View>
        </View>

        {/* RGPD Consent avec espace réduit */}
        <View style={{ marginTop: 5, flexDirection: 'row', alignItems: 'center' }}>
          <View style={{ marginRight: 10 }}>
            <CheckBox checked={false} />
          </View>
          <View style={{ flex: 1 }}>
            <Text style={[styles.label, { fontSize: baseFontSize - 1 }]}>
              Je consens à ce que l'association El Mektoub collecte et utilise mes données personnelles renseignées dans ce formulaire. 
              Ces informations sont nécessaires pour la gestion administrative de l'association et le suivi des adhérents. 
              Conformément au RGPD, je dispose d'un droit d'accès, de rectification et de suppression de mes données 
              que je peux exercer en contactant l'association.
            </Text>
          </View>
        </View>

        {/* Contact Information */}
        <View style={{ marginTop: 5, alignItems: 'center' }}>
          <Text style={[styles.label, { fontSize: baseFontSize - 1 }]}>
            Pour nous contacter : contact@associationelmektoub.org
          </Text>
        </View>

        {/* Signature avec espace réduit */}
        <View style={[styles.signature, { marginTop: 10 }]}>
          <Text style={styles.label}>FAIT À.........................LE..........................</Text>
        </View>

        {/* Contribution Years */}
        <CheckBoxTable lastUpdatedYear={lastUpdatedYear} />
      </Page>
    </Document>
  );
};

export default AdherentPDF;
