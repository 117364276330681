import React, { useState, useContext, useEffect, useRef } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  useToast,
  VStack,
  HStack,
  IconButton,
  Box,
  Flex,
  Text,
  FormErrorMessage,
  Divider,
  SimpleGrid,
  Heading,
  useColorModeValue,
} from '@chakra-ui/react';
import { MdAdd, MdDelete, MdUpload } from 'react-icons/md';
import { AuthContext } from 'contexts/AuthContext';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import 'antd/dist/reset.css';

// Configurez dayjs pour utiliser le français
dayjs.locale('fr');

const AddMemberModal = ({ isOpen, onClose, onSave, selectedMember }) => {
  const initialMemberState = {
    mrName: '',
    mrsName: '',
    socialSecurityNumber: '',
    socialSecurityNumberLine2: '',
    birthDate: '',
    birthDateLine2: '',
    address: '',
    phone: '',
    email: '',
    nationality: '',
    burialSite: '',
    maritalStatus: '',
    contributionStatus: '',
    children: [{ name: '', birthDate: '' }],
    delegateName: '',
  };

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [delegates, setDelegates] = useState([]);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchDelegates = async () => {
      const response = await fetch('https://app.falconmarketing.fr:3004/delegates');
      if (response.ok) {
        const data = await response.json();
        setDelegates(data.delegateNames);
      }
    };
  
    fetchDelegates();
  }, []);

  const toast = useToast();

  const schema = yup.object().shape({
    mrName: yup.string(),
    mrsName: yup.string(),
    socialSecurityNumber: yup.string(),
    socialSecurityNumberLine2: yup.string(),
    birthDate: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
    birthDateLine2: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
    address: yup.string(),
    phone: yup.string(),
    email: yup.string().email('Email invalide'),
    nationality: yup.string(),
    burialSite: yup.string(),
    maritalStatus: yup.string(),
    contributionStatus: yup.string(),
    children: yup.array().of(
      yup.object().shape({
        name: yup.string(),
        birthDate: yup.date().nullable().transform((curr, orig) => orig === '' ? null : curr),
      })
    ),
    delegateName: yup.string(),
  });

  const { control, handleSubmit, reset, setValue, watch } = useForm({
    resolver: yupResolver(schema),
    defaultValues: initialMemberState,
  });

  const children = watch('children');

  useEffect(() => {
    if (selectedMember) {
      reset(selectedMember);
    }
  }, [selectedMember, reset]);

  const handleAddChild = () => {
    setValue('children', [...children, { name: '', birthDate: '' }]);
  };

  const handleRemoveChild = (index) => {
    setValue('children', children.filter((_, i) => i !== index));
  };

  const onSubmit = async (data) => {
    const method = data.id ? 'PUT' : 'POST';
    const adherentUrl = `https://app.falconmarketing.fr:3004/adherents${data.id ? `/${data.id}` : ''}`;
    const contributionAmount = calculateContributionAmount(data.maritalStatus);
  
    try {
      // Sauvegardez d'abord l'adhérent
      const adherentResponse = await fetch(adherentUrl, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...data,
          cotisation: contributionAmount, // Ajoutez le montant de la cotisation
          children: undefined, // Retirer les enfants de l'objet membre pour cette requête
        }),
      });
  
      if (!adherentResponse.ok) {
        const errorText = await adherentResponse.text();
        throw new Error(errorText);
      }
  
      const adherentData = await adherentResponse.json();
      const adherentId = data.id ? data.id : adherentData.adherentId; // Utiliser l'ID existant si en mode édition
  
      // Ensuite, ajoutez chaque enfant si l'adhérent est nouveau ou si des enfants sont ajoutés
      if (!data.id || data.children.length > 0) {
        for (let child of data.children) {
          await fetch(`https://app.falconmarketing.fr:3004/children/${adherentId}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(child),
          });
        }
      }
  
      // Envoyer les fichiers sélectionnés
      if (selectedFiles.length > 0) {
        const formData = new FormData();
        selectedFiles.forEach((file) => {
          formData.append('files', file);
        });
        
        const fileResponse = await fetch(`https://app.falconmarketing.fr:3004/adherents/${adherentId}/files`, {
          method: 'POST',
          body: formData,
        });
  
        if (!fileResponse.ok) {
          const errorText = await fileResponse.text();
          throw new Error(errorText);
        }
      }
  
      toast({
        title: data.id ? 'Membre mis à jour' : 'Membre ajouté',
        description: `Les informations du membre ont été ${data.id ? 'mises à jour' : 'ajoutées'} avec succès.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
  
      onClose(); // Fermer la modale
      reset(initialMemberState); // Réinitialiser l'état du formulaire
      setSelectedFiles([]); // Réinitialiser les fichiers sélectionnés
    } catch (error) {
      toast({
        title: 'Erreur',
        description: `Une erreur s'est produite lors de ${data.id ? "la mise à jour" : "l'ajout"} du membre: ${error.message}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const calculateContributionAmount = (maritalStatus) => {
    switch (maritalStatus) {
      case 'married':
      case 'divorcedWithChildren':
        return 110;
      case 'single':
      case 'widowed':
      case 'divorcedWithoutChildren':
        return 55;
      default:
        return 0;
    }
  };

  useEffect(() => {
    const maritalStatus = watch('maritalStatus');
    if (maritalStatus) {
      const amount = calculateContributionAmount(maritalStatus);
      setValue('cotisation', amount);
    }
  }, [watch('maritalStatus'), setValue]);

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setSelectedFiles(prevFiles => [...prevFiles, ...newFiles]);
    // Reset the input value to allow selecting the same file again
    e.target.value = '';
  };

  const handleRemoveFile = (indexToRemove) => {
    setSelectedFiles(prevFiles => prevFiles.filter((_, index) => index !== indexToRemove));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      console.log('Selected files:', selectedFiles);
      
      selectedFiles.forEach((file) => {
        console.log('Adding file to FormData:', file.name);
        formData.append('files', file);
      });

      // Log the FormData content
      for (let pair of formData.entries()) {
        console.log(pair[0], pair[1]);
      }

      // ... reste du code ...
    } catch (error) {
      console.error('Error in handleSave:', error);
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  // Définition des couleurs pour le mode sombre/clair
  const modalBg = useColorModeValue('white', 'gray.800');
  const headerBg = useColorModeValue('blue.500', 'blue.600');
  const boxBg = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const headingColor = useColorModeValue('blue.600', 'blue.200');
  const textColor = useColorModeValue('gray.800', 'whiteAlpha.900');
  const labelColor = useColorModeValue('gray.700', 'gray.200');
  const inputBg = useColorModeValue('white', 'gray.700');
  const placeholderColor = useColorModeValue('gray.400', 'gray.500');
  const hoverBg = useColorModeValue('gray.100', 'gray.600');

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent maxHeight="90vh" overflowY="auto" bg={modalBg}>
        <ModalHeader bg={headerBg} color="white">
          Ajouter un nouveau membre
        </ModalHeader>
        <ModalCloseButton color="white" />
        <ModalBody py={6}>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <VStack spacing={6} align="stretch">
              {/* Section Informations Principales */}
              <Box 
                borderWidth="1px" 
                borderRadius="lg" 
                bg={boxBg} 
                shadow="sm"
                borderColor={borderColor}
              >
                <Box p={4} borderBottomWidth="1px" borderColor={borderColor}>
                  <Heading size="md" color={headingColor}>
                    Informations Principales
                  </Heading>
                </Box>
                <Box p={6}>
                  <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                    <Controller
                      name="mrName"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl isInvalid={!!error}>
                          <FormLabel color={labelColor}>
                            Nom et Prénom (Adhérent)
                          </FormLabel>
                          <Input 
                            {...field} 
                            placeholder="Nom et prénom"
                            bg={inputBg}
                            borderColor={borderColor}
                            color={textColor}
                            _placeholder={{ color: placeholderColor }}
                            autoComplete="off"
                          />
                          {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                        </FormControl>
                      )}
                    />
                    <Controller
                      name="mrsName"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl isInvalid={!!error}>
                          <FormLabel color={labelColor}>
                            Nom et Prénom (Conjoint)
                          </FormLabel>
                          <Input 
                            {...field} 
                            placeholder="Nom et prénom"
                            bg={inputBg}
                            borderColor={borderColor}
                            color={textColor}
                            _placeholder={{ color: placeholderColor }}
                            autoComplete="off"
                          />
                          {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                        </FormControl>
                      )}
                    />
                  </SimpleGrid>
                </Box>
              </Box>

              {/* Section Sécurité Sociale */}
              <Box 
                borderWidth="1px" 
                borderRadius="lg" 
                bg={boxBg} 
                shadow="sm"
                borderColor={borderColor}
              >
                <Box p={4} borderBottomWidth="1px" borderColor={borderColor}>
                  <Heading size="md" color={headingColor}>
                    Sécurité Sociale
                  </Heading>
                </Box>
                <Box p={6}>
                  <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                    <Controller
                      name="socialSecurityNumber"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl isInvalid={!!error}>
                          <FormLabel color={labelColor}>
                            N° Sécurité Sociale (Adhérent)
                          </FormLabel>
                          <Input 
                            {...field} 
                            placeholder="Numéro de sécurité sociale"
                            bg={inputBg}
                            borderColor={borderColor}
                            color={textColor}
                            _placeholder={{ color: placeholderColor }}
                            autoComplete="off"
                          />
                          {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                        </FormControl>
                      )}
                    />
                    <Controller
                      name="socialSecurityNumberLine2"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl isInvalid={!!error}>
                          <FormLabel color={labelColor}>
                            N° Sécurité Sociale (Conjoint)
                          </FormLabel>
                          <Input 
                            {...field} 
                            placeholder="Numéro de sécurité sociale"
                            bg={inputBg}
                            borderColor={borderColor}
                            color={textColor}
                            _placeholder={{ color: placeholderColor }}
                            autoComplete="off"
                          />
                          {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                        </FormControl>
                      )}
                    />
                  </SimpleGrid>
                </Box>
              </Box>

              {/* Section Dates de Naissance */}
              <Box 
                borderWidth="1px" 
                borderRadius="lg" 
                bg={boxBg} 
                shadow="sm"
                borderColor={borderColor}
              >
                <Box p={4} borderBottomWidth="1px" borderColor={borderColor}>
                  <Heading size="md" color={headingColor}>
                    Dates de Naissance
                  </Heading>
                </Box>
                <Box p={6}>
                  <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                    <Controller
                      name="birthDate"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl isInvalid={!!error}>
                          <FormLabel color={labelColor}>
                            Date de naissance
                          </FormLabel>
                          <DatePicker
                            value={field.value ? dayjs(field.value) : null}
                            onChange={(date) => field.onChange(date ? date.format('YYYY-MM-DD') : null)}
                            format={{
                              format: 'DD/MM/YYYY',
                              type: 'mask'
                            }}
                            placeholder="JJ/MM/AAAA"
                            style={{
                              width: '100%',
                              backgroundColor: inputBg,
                              borderColor: borderColor,
                              color: textColor
                            }}
                          />
                          {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                        </FormControl>
                      )}
                    />
                    <Controller
                      name="birthDateLine2"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl isInvalid={!!error}>
                          <FormLabel color={labelColor}>
                            Date de naissance (conjoint)
                          </FormLabel>
                          <DatePicker
                            value={field.value ? dayjs(field.value) : null}
                            onChange={(date) => field.onChange(date ? date.format('YYYY-MM-DD') : null)}
                            format={{
                              format: 'DD/MM/YYYY',
                              type: 'mask'
                            }}
                            placeholder="JJ/MM/AAAA"
                            style={{
                              width: '100%',
                              backgroundColor: inputBg,
                              borderColor: borderColor,
                              color: textColor
                            }}
                          />
                          {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                        </FormControl>
                      )}
                    />
                  </SimpleGrid>
                </Box>
              </Box>

              {/* Section Coordonnées */}
              <Box 
                borderWidth="1px" 
                borderRadius="lg" 
                bg={boxBg} 
                shadow="sm"
                borderColor={borderColor}
              >
                <Box p={4} borderBottomWidth="1px" borderColor={borderColor}>
                  <Heading size="md" color={headingColor}>
                    Coordonnées
                  </Heading>
                </Box>
                <Box p={6}>
                  <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                    <Controller
                      name="address"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl isInvalid={!!error}>
                          <FormLabel color={labelColor}>
                            Adresse
                          </FormLabel>
                          <Input 
                            {...field} 
                            placeholder="Adresse complète"
                            bg={inputBg}
                            borderColor={borderColor}
                            color={textColor}
                            _placeholder={{ color: placeholderColor }}
                            autoComplete="off"
                          />
                          {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                        </FormControl>
                      )}
                    />
                    <Controller
                      name="phone"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl isInvalid={!!error}>
                          <FormLabel color={labelColor}>
                            Téléphone
                          </FormLabel>
                          <Input 
                            {...field} 
                            placeholder="Numéro de téléphone"
                            bg={inputBg}
                            borderColor={borderColor}
                            color={textColor}
                            _placeholder={{ color: placeholderColor }}
                            autoComplete="off"
                          />
                          {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                        </FormControl>
                      )}
                    />
                    <Controller
                      name="email"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl isInvalid={!!error}>
                          <FormLabel color={labelColor}>
                            Email
                          </FormLabel>
                          <Input 
                            {...field} 
                            placeholder="Adresse email"
                            bg={inputBg}
                            borderColor={borderColor}
                            color={textColor}
                            _placeholder={{ color: placeholderColor }}
                            autoComplete="off"
                          />
                          {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                        </FormControl>
                      )}
                    />
                  </SimpleGrid>
                </Box>
              </Box>

              {/* Section Informations Complémentaires */}
              <Box 
                borderWidth="1px" 
                borderRadius="lg" 
                bg={boxBg} 
                shadow="sm"
                borderColor={borderColor}
              >
                <Box p={4} borderBottomWidth="1px" borderColor={borderColor}>
                  <Heading size="md" color={headingColor}>
                    Informations Complémentaires
                  </Heading>
                </Box>
                <Box p={6}>
                  <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                    <Controller
                      name="nationality"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl isInvalid={!!error}>
                          <FormLabel color={labelColor}>
                            Nationalité
                          </FormLabel>
                          <Input 
                            {...field} 
                            placeholder="Nationalité"
                            bg={inputBg}
                            borderColor={borderColor}
                            color={textColor}
                            _placeholder={{ color: placeholderColor }}
                            autoComplete="off"
                          />
                          {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                        </FormControl>
                      )}
                    />
                    <Controller
                      name="burialSite"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl isInvalid={!!error}>
                          <FormLabel color={labelColor}>
                            Lieu d'inhumation
                          </FormLabel>
                          <Input 
                            {...field} 
                            placeholder="Lieu d'inhumation"
                            bg={inputBg}
                            borderColor={borderColor}
                            color={textColor}
                            _placeholder={{ color: placeholderColor }}
                            autoComplete="off"
                          />
                          {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                        </FormControl>
                      )}
                    />
                    <Controller
                      name="maritalStatus"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl isInvalid={!!error}>
                          <FormLabel color={labelColor}>
                            Situation familiale
                          </FormLabel>
                          <Select 
                            {...field} 
                            placeholder="Sélectionnez la situation familiale"
                            bg={inputBg}
                            borderColor={borderColor}
                            color={textColor}
                            autoComplete="off"
                          >
                            <option value="married">Marié</option>
                            <option value="single">Célibataire</option>
                            <option value="divorcedWithChildren">Divorcé avec enfants</option>
                            <option value="divorcedWithoutChildren">Divorcé sans enfants</option>
                            <option value="widowed">Veuf/Veuve</option>
                          </Select>
                          {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                        </FormControl>
                      )}
                    />
                  </SimpleGrid>
                </Box>
              </Box>

              {/* Section Délégué */}
              <Box 
                borderWidth="1px" 
                borderRadius="lg" 
                bg={boxBg} 
                shadow="sm"
                borderColor={borderColor}
              >
                <Box p={4} borderBottomWidth="1px" borderColor={borderColor}>
                  <Heading size="md" color={headingColor}>
                    Délégué
                  </Heading>
                </Box>
                <Box p={6}>
                  <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                    <Controller
                      name="delegateName"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl isInvalid={!!error}>
                          <FormLabel color={labelColor}>
                            Nom du délégué
                          </FormLabel>
                          <Select 
                            {...field} 
                            placeholder="Sélectionnez un délégué"
                            bg={inputBg}
                            borderColor={borderColor}
                            color={textColor}
                            autoComplete="off"
                          >
                            {delegates
                              .slice()
                              .sort((a, b) => a.localeCompare(b, 'fr', { sensitivity: 'base' }))
                              .map((delegate, index) => (
                                <option key={index} value={delegate}>
                                  {delegate}
                                </option>
                              ))}
                          </Select>
                          {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                        </FormControl>
                      )}
                    />
                  </SimpleGrid>
                </Box>
              </Box>

              {/* Section Enfants */}
              <Box 
                borderWidth="1px" 
                borderRadius="lg" 
                bg={boxBg} 
                shadow="sm"
                borderColor={borderColor}
              >
                <Box p={4} borderBottomWidth="1px" borderColor={borderColor}>
                  <Heading size="md" color={headingColor}>
                    Enfants
                  </Heading>
                </Box>
                <Box p={6}>
                  <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                    <Controller
                      name="children"
                      control={control}
                      render={({ field }) => (
                        <FormControl>
                          <FormLabel color={labelColor}>
                            Enfants
                          </FormLabel>
                          {field.value.map((child, index) => (
                            <HStack key={index} mb={2}>
                              <Controller
                                name={`children[${index}].name`}
                                control={control}
                                render={({ field }) => (
                                  <Input 
                                    {...field} 
                                    placeholder="Nom de l'enfant"
                                    bg={inputBg}
                                    borderColor={borderColor}
                                    color={textColor}
                                    _placeholder={{ color: placeholderColor }}
                                    autoComplete="off"
                                  />
                                )}
                              />
                              <Controller
                                name={`children[${index}].birthDate`}
                                control={control}
                                render={({ field }) => (
                                  <DatePicker
                                    value={field.value ? dayjs(field.value) : null}
                                    onChange={(date) => field.onChange(date ? date.format('YYYY-MM-DD') : null)}
                                    format={{
                                      format: 'DD/MM/YYYY',
                                      type: 'mask'
                                    }}
                                    placeholder="JJ/MM/AAAA"
                                    style={{
                                      backgroundColor: inputBg,
                                      borderColor: borderColor,
                                      color: textColor
                                    }}
                                  />
                                )}
                              />
                              <IconButton
                                aria-label="Supprimer l'enfant"
                                icon={<MdDelete />}
                                onClick={() => handleRemoveChild(index)}
                                bg={inputBg}
                                color={textColor}
                                _hover={{ bg: hoverBg }}
                              />
                            </HStack>
                          ))}
                          <Button leftIcon={<MdAdd />} colorScheme="teal" onClick={handleAddChild}>
                            Ajouter un enfant
                          </Button>
                        </FormControl>
                      )}
                    />
                  </SimpleGrid>
                </Box>
              </Box>

              {/* Section Statut de cotisation */}
              <Box 
                borderWidth="1px" 
                borderRadius="lg" 
                bg={boxBg} 
                shadow="sm"
                borderColor={borderColor}
              >
                <Box p={4} borderBottomWidth="1px" borderColor={borderColor}>
                  <Heading size="md" color={headingColor}>
                    Statut de cotisation
                  </Heading>
                </Box>
                <Box p={6}>
                  <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                    <Controller
                      name="contributionStatus"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl isInvalid={!!error}>
                          <FormLabel color={labelColor}>
                            Statut de cotisation
                          </FormLabel>
                          <Select 
                            {...field} 
                            placeholder="Sélectionnez le statut de cotisation"
                            bg={inputBg}
                            borderColor={borderColor}
                            color={textColor}
                            autoComplete="off"
                          >
                            <option value="À jour">À jour</option>
                            <option value="En retard">En retard</option>
                            <option value="Radié">Radié</option>
                          </Select>
                          {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                        </FormControl>
                      )}
                    />
                  </SimpleGrid>
                </Box>
              </Box>
            </VStack>
          </form>
          <Box mt={4}>
            <FormLabel color={labelColor}>Pièces justificatives</FormLabel>
            <Flex alignItems="center" mb={2}>
              <IconButton
                aria-label="Ajouter des fichiers"
                icon={<MdUpload />}
                onClick={handleUploadClick}
                bg={inputBg}
                color={textColor}
                _hover={{ bg: hoverBg }}
              />
              <input
                type="file"
                multiple
                onChange={handleFileChange}
                style={{ display: 'none' }}
                ref={fileInputRef}
                accept="application/pdf,image/*"
              />
            </Flex>
            {selectedFiles.length > 0 && (
              <Flex flexWrap="wrap">
                {selectedFiles.map((file, index) => (
                  <Box 
                    key={index} 
                    mr={2} 
                    mb={2} 
                    p={2} 
                    borderWidth="1px" 
                    borderRadius="md"
                    bg={inputBg}
                  >
                    <Flex alignItems="center">
                      <Text color={textColor} mr={2}>{file.name}</Text>
                      <IconButton
                        size="sm"
                        icon={<MdDelete />}
                        onClick={() => handleRemoveFile(index)}
                        aria-label="Supprimer le fichier"
                        variant="ghost"
                        colorScheme="red"
                      />
                    </Flex>
                  </Box>
                ))}
              </Flex>
            )}
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handleSubmit(onSubmit)}>
            Sauvegarder
          </Button>
          <Button onClick={onClose}>Annuler</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddMemberModal;